// Custom theme-externi typography rules

:root {
  font-family: $fontFamily;
  color: var(--navy);

  h1 {
    font-family: "Almarena Neue";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  h2 {
    font-family: "Almarena Neue";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  h3 {
    font-family: "Almarena Neue";
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  h4 {
    font-family: "Almarena Neue";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  h5 {
    font-family: "Almarena Neue";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .title-1-regular {
    font-family: "Almarena Neue";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .title-1-semibold {
    font-family: "Almarena Neue";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .title-2-regular {
    font-family: "Almarena Neue";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .subtitle-1 {
    font-family: "Inter";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .subtitle-2 {
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .subtitle-3-semibold {
    font-family: "Inter";
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .button-1-semibold {
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.03rem;
  }

  .button-2-semibold {
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.03rem;
  }

  .button-3-semibold {
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.03rem;
  }

  .body-1-regular {
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .body-1-regular-mobile {
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .body-1-semibold {
    font-family: "Inter";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .body-2-regular {
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .body-2-semibold {
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .body-2-medium {
    font-family: "Inter";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.015rem;
  }

  .body-3-regular {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .role-title {
    font-family: Inter;
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .h2-mobile {
    font-family: "Almarena Neue";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
