// Avatar
.p-avatar {
  img {
    object-fit: cover;
  }

  &-text {
    line-height: 156%;
  }
}

// Badge
.p-badge {
  @apply body-2-regular;
  background-color: var(--green-06);
  border-width: 3px;
  border-color: var(--white);
}

.p-overlay-badge .p-badge {
  transform: translate(45%, -45%);
}

// Chip
.p-chip {
  padding: 0.125rem $chipPadding;
  border: 1px solid $chipBorderColor;

  .p-chip-text {
    @apply body-1-regular;
    margin: $chipMargin 0;
  }
}
