@import "src/styles/mixins/apply-class-styles.scss";


$typographyStyles: (
  "subtitle-1": subtitle-1,
  "subtitle-2": subtitle-2,
  "subtitle-3-semibold": subtitle-3-semibold,
  "button-1-semibold": button-1-semibold,
  "button-2-semibold": button-2-semibold,
  "button-3-semibold": button-3-semibold,
  "body-1-regular": body-1-regular,
  "body-1-regular-mobile": body-1-regular-mobile,
  "body-1-semibold": body-1-semibold,
  "body-2-regular": body-2-regular,
  "body-2-semibold": body-2-semibold,
  "body-2-medium": body-2-medium,
  "body-3-regular": body-3-regular,
  "role-title": role-title,
  "h2-mobile": h2-mobile,
);

@layer responsive {
  @media (screen(md)) {
    @include apply-styles("md", $typographyStyles);
  }

  @media (screen(max-md)) {
    @include apply-styles("max-md", $typographyStyles);
  }
}
