.p-breadcrumb {
  background: transparent;
  border: none;
  padding: 0;

  .p-breadcrumb-list {
    li {
      &.p-menuitem-separator,
      .p-menuitem-text {
        font-family: "Inter";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      &.p-menuitem-separator {
        margin: 0 0.25rem;
        color: $breadcrumbSeparatorColor;
      }

      &:not(:last-child) {
        .p-menuitem-text {
          color: $breadcrumbItemTextColor;
        }
      }

      &:last-child {
        .p-menuitem-text {
          color: $textColor;
        }
      }
    }
  }
}
