// Theme Specific Variables
$primaryColor: #5269fd;
$primaryDarkColor: #3d55ee;
$primaryDarkerColor: #3d55ee;
$primaryTextColor: #ffffff;
$errorColor: var(--red-07);
$errorDarkColor: var(--red-08);
$warningColor: var(--orange-08);

// Components variables
@import "./variables/general";
@import "./variables/form";
@import "./variables/button";
@import "./variables/panel";
@import "./variables/_data";
@import "./variables/_overlay";
@import "./variables/_message";
@import "./variables/_menu";
@import "./variables/_media";
@import "./variables/_misc";
