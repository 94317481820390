@import "@platform-app/styles/mixins/custom-scrollbar.scss";

$tableEmptyMessageHeaderGap: 29rem;

:root {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
  scrollbar-gutter: auto;

  &::-webkit-scrollbar-track {
    background-color: var(--grey-01);
  }
}

// Fieldset
fieldset label {
  @apply body-1-regular;

  color: var(--navy);
  margin-bottom: 0.5rem;
  line-height: normal;
}

// Link
a {
  color: var(--purple-06);

  @apply body-2-semibold;

  &:hover,
  &:focus {
    color: var(--purple-07);
  }
}

// Small
small {
  @apply body-2-regular;
}

// Material Symbols
.filled.material-symbols-rounded {
  font-variation-settings: "FILL" 1;
}

// Circle progress
circle-progress tspan {
  @apply body-2-semibold;
}

// Height of table empty message
.empty-msg-height {
  height: calc(100vh - $tableEmptyMessageHeaderGap);
}

// Scrollable container
.scrollable {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
  overflow: auto;
  overscroll-behavior: none;
}
