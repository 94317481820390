// Editor Container
.tox-tinymce {
  min-height: calc(100vh - 5rem) !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}

:host ::ng-deep .tox-tinymce {
  font-family: Inter !important;
  font-style: normal !important;
}

.tox-edit-area {
  order: 1 !important;
  display: flex;
  justify-content: center;
  margin-top: 3.75rem !important;
  margin-bottom: 2rem !important;
  background-color: var(--grey-01) !important;

  &__iframe {
    width: 58.75rem !important;
  }

  &::before {
    border: none !important;
  }
}

.toolbar-hidden .tox-edit-area {
  margin-top: 0 !important;
}

// Editor Header
.tox-editor-header {
  border-bottom: 1px solid var(--grey-02) !important;
  box-shadow: none !important;
  height: 4rem !important;
  padding: 1.25rem 1rem !important;
  align-items: center !important;

  &--empty {
    background-color: white !important;
  }
}

// Editor Toolbar
.tox-toolbar {
  &__primary {
    align-items: center !important;
    padding: 0 !important;
  }

  &__group {
    align-items: center !important;
    padding: 0 1rem !important;
    gap: 1rem !important;
    height: 1.5rem !important;

    &:not(:last-of-type) {
      border-right: 1px solid var(--grey-02) !important;
    }

    .tox-number-input {
      margin: 0 !important;
      height: 1.5rem !important;

      button {
        height: 1.5rem !important;
      }
    }
  }

  &--scrolling {
    overflow: hidden !important;
  }
}

.toolbar-hidden .tox-editor-header {
  display: none !important;
}

// Dropdown Items
.tox-collection__item--active:not(.tox-collection__item--state-disabled):not(
    :hover
  ) {
  background-color: var(--purple-01) !important;
  color: var(--navy) !important;
}

.tox-collection__item--active:hover:not(.tox-collection__item--state-disabled) {
  background-color: var(--grey-01) !important;
  color: var(--navy) !important;
}

// Inputs
.tox-number-input,
.tox-tbtn--bespoke {
  &:not(:hover) {
    background-color: white !important;
  }
}

.tox-textfield:focus,
.tox-input-wrapper:has(input:focus)::after {
  border-color: var(--purple-06) !important;
  box-shadow: none !important;
}

// Buttons
.tox-tbtn {
  margin: 0 !important;
  height: 1.5rem !important;

  &:not(.tox-tbtn--select) {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  &:hover:not(.tox-tbtn--disabled) {
    cursor: pointer !important;
  }

  svg {
    fill: var(--navy) !important;
  }

  &:focus::after {
    box-shadow: none !important;
  }

  &__select-label {
    font-family: Inter !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    cursor: pointer !important;
  }

  &--select {
    width: fit-content !important;
  }
}

.tox-split-button {
  margin: 0 !important;
}
