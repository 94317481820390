.p-accordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p-accordiontab {
    :not(.p-accordion-tab-active) {
      .p-accordion-header-link {
        transition: border-color .6s ease-out, border-radius .6s ease-out; 
      }

      .p-accordion-toggle-icon {
        transform: rotate(270deg);
      }
    }

    .p-accordion-tab-active {
      .p-accordion-header-link {
        border-bottom: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: border-color .1s ease-out, border-radius .1s ease-out; 
      }

      .p-accordion-toggle-icon {
        transform: rotate(360deg);
      }
    }

    .p-accordion-header-link {
      border: 1px solid var(--grey-02);
    }

    .p-accordion-content {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    .p-accordion-header-link {
      @apply button-1-semibold;

      border-radius: 0.5rem;
      justify-content: space-between;
      background-color: white;
      color: var(--navy);
      font-size: 0.875rem !important;

      &:hover,
      &:active,
      &:focus {
        color: var(--navy);
      }
    }

    .p-accordion-toggle-icon {
      order: 1;
      color: var(--grey-05);
    }
  }
}
