// Button
.p-button {
  border-radius: 0.5rem;

  .p-button-label {
    @apply button-2-semibold;
  }

  &.p-button-lg {
    padding: $buttonPadding;

    .p-button-label {
      @apply button-1-semibold;
    }
  }

  &.p-button-sm {
    display: flex;
    align-items: center;

    .p-button-label {
      @apply body-2-semibold;
    }
  }

  // Disabled state
  &:disabled {
    background: var(--grey-03);
    border-color: var(--grey-03);
  }

  &.p-button-outlined {
    background: transparent;

    // Disabled state
    &:disabled {
      border-color: var(--grey-05);
      color: var(--grey-05);
    }

    &.p-button-danger {
      &:not(:disabled):hover,
      &:not(:disabled):focus,
      &:not(:disabled):focus-visible {
        border-color: $errorColor;
        color: $errorDarkColor;
      }
    }

    // Focus & hover state
    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):focus-visible {
      border-color: $primaryDarkColor;
      color: $primaryDarkColor;
    }
  }

  &.p-button-text {
    padding: 0;

    &:not(:disabled):hover,
    &:not(:disabled):focus,
    &:not(:disabled):focus-visible {
      background: transparent;
      border-color: transparent;
      color: var(--purple-07);
    }

    &:disabled {
      background: transparent;
      border-color: transparent;
      color: var(--grey-05);
    }
  }

  // Loading state
  &.p-button-loading {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .p-button-loading-icon {
      height: 0.875rem;
      width: 0.875rem;
    }
  }
}
