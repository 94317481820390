// Menu
.p-menuitem-text {
  @apply body-1-regular;
}

.p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-menu-overlay {
  margin-top: 0.5rem !important;
}

.p-menuitem-separator {
  border-color: var(--grey-02);
}
