@mixin scrollbar(
  $size,
  $padding-top,
  $thumb-box-shadow,
  $thumb-border-radius
) {
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    padding-top: $padding-top;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: $thumb-box-shadow;
    border-radius: $thumb-border-radius;
  }
}
