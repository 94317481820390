@import "@platform-app/styles/mixins/custom-scrollbar.scss";

// Confirm Dialog
.p-confirm-dialog {
  width: 34.9375rem;
  border-radius: 1rem;

  .p-confirm-dialog-message {
    margin: 0;
    color: var(--grey-07);

    @apply subtitle-2;
  }

  .p-dialog-header {
    border: none;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .p-dialog-footer {
    gap: 1rem;
    display: flex;
    justify-content: end;
    border: none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    button {
      margin: 0;
      height: 2.5rem;

      @extend .p-button-lg;
    }
  }
}

// Dialog
.p-dialog {
  &-content {
    @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);

    &::-webkit-scrollbar-track-piece {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
  &-footer {
    button {
      min-width: $dialogButtonMinWidth;
      justify-content: center;
    }
  }

  &-content:not(:has(.overflow-auto, .overflow-y-auto)) {
    scrollbar-gutter: unset;
  }

  &-maximized {
    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      border-radius: 0;
    }
  }

  &:has(.p-dialog-content.overflow-auto, .p-dialog-content.overflow-y-auto)
    .p-dialog-footer {
    border-top: $scrollableDialogFooterBorder;
  }
}

// Tooltip
.p-tooltip-text {
  @apply body-2-regular;
  color: var(--navy);
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.p-tooltip.tooltip-black {
  .p-tooltip-text {
    background: var(--black);
    border: var(--black);
    color: white;
    border-radius: 0.25rem;
    padding: 0.375rem 0.5rem;
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--black);
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: var(--black);
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: var(--black);
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--black);
  }
}
