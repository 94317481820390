// Almarena Neue - Medium
@font-face {
  font-family: "Almarena Neue";
  font-style: normal;
  font-weight: 500;
  src:
    url("./fonts/AlmarenaNeue-Medium.otf") format("otf"),
    url("./fonts/AlmarenaNeue-Medium.woff") format("woff"),
    url("./fonts/AlmarenaNeue-Medium.woff2") format("woff2");
}

// Inter - Regular
@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Inter-Regular.ttf") format("ttf");
}

// Inter - Semibold
@font-face {
  font-family: "Inter-Semibold";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Inter-SemiBold.ttf") format("ttf");
}
