@import "_mixins";
@import "_colors";

@import "./components/input/_editor";

@layer primeng {
  @import "_common";

  //Input
  @import "./components/input/_autocomplete";
  @import "./components/input/_calendar";
  @import "./components/input/_cascadeselect";
  @import "./components/input/_checkbox";
  @import "./components/input/_chips";
  @import "./components/input/_colorpicker";
  @import "./components/input/_dropdown";
  @import "./components/input/_iconfield";
  @import "./components/input/_inputgroup";
  @import "./components/input/_inputicon";
  @import "./components/input/_inputmask";
  @import "./components/input/_inputnumber";
  @import "./components/input/_inputotp";
  @import "./components/input/_inputswitch";
  @import "./components/input/_inputtext";
  @import "./components/input/_listbox";
  @import "./components/input/_multiselect";
  @import "./components/input/_password";
  @import "./components/input/_radiobutton";
  @import "./components/input/_rating";
  @import "./components/input/_selectbutton";
  @import "./components/input/_slider";
  @import "./components/input/_togglebutton";
  @import "./components/input/_treeselect";

  //Button
  @import "./components/button/_button";
  @import "./components/button/_speeddial";
  @import "./components/button/_splitbutton";

  //Data
  @import "./components/data/_carousel";
  @import "./components/data/_datatable";
  @import "./components/data/_dataview";
  @import "./components/data/_filter";
  @import "./components/data/_orderlist";
  @import "./components/data/_organizationchart";
  @import "./components/data/_paginator";
  @import "./components/data/_picklist";
  @import "./components/data/_timeline";
  @import "./components/data/_tree";
  @import "./components/data/_treetable";
  @import "./components/data/_virtualscroller";

  //Panel
  @import "./components/panel/_accordion";
  @import "./components/panel/_card";
  @import "./components/panel/_divider";
  @import "./components/panel/_fieldset";
  @import "./components/panel/_panel";
  @import "./components/panel/_scrollpanel";
  @import "./components/panel/_splitter";
  @import "./components/panel/_stepper";
  @import "./components/panel/_tabview";
  @import "./components/panel/_toolbar";

  //Overlay
  @import "./components/overlay/_confirmpopup";
  @import "./components/overlay/_dialog";
  @import "./components/overlay/_overlaypanel";
  @import "./components/overlay/_sidebar";
  @import "./components/overlay/_tooltip";

  //File
  @import "./components/file/_fileupload";

  //Menu
  @import "./components/menu/_breadcrumb";
  @import "./components/menu/_contextmenu";
  @import "./components/menu/_dock";
  @import "./components/menu/_megamenu";
  @import "./components/menu/_menu";
  @import "./components/menu/_menubar";
  @import "./components/menu/_panelmenu";
  @import "./components/menu/_slidemenu";
  @import "./components/menu/_steps";
  @import "./components/menu/_tabmenu";
  @import "./components/menu/_tieredmenu";

  //Messages
  @import "./components/messages/_inlinemessage";
  @import "./components/messages/_message";
  @import "components/messages/toast";

  //MultiMedia
  @import "./components/multimedia/_galleria";
  @import "./components/multimedia/_image";

  //Misc
  @import "./components/misc/_avatar";
  @import "./components/misc/_badge";
  @import "./components/misc/_blockui";
  @import "./components/misc/_chip";
  @import "./components/misc/_inplace";
  @import "./components/misc/_metergroup";
  @import "./components/misc/_progressbar";
  @import "./components/misc/_scrolltop";
  @import "./components/misc/_skeleton";
  @import "./components/misc/_tag";
  @import "./components/misc/_terminal";
}
