// Because of how Angular builds the SCSS,
// Layers must be in a CSS file, not SCSS and must be imported first
@import "styles/layers.css";

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import "node_modules/primeng/resources/primeng.min.css" layer(primeng);

@import "primeicons/primeicons.css";
@import "node_modules/material-symbols/index.css" layer(tailwind-utilities);

// Custom theme-externi layer
@import "styles/theme-externi/theme.scss" layer(theme-externi);

// Quill editor styles
@import "node_modules/quill/dist/quill.core.css" layer(primeng);
@import "node_modules/quill/dist/quill.snow.css" layer(primeng);

// TinyMCE editor layer
@import "node_modules/tinymce/skins/ui/oxide/content.min.css" layer(tinymce);
@import "node_modules/tinymce/skins/ui/oxide/content.inline.min.css"
  layer(tinymce);
@import "node_modules/tinymce/skins/ui/oxide/skin.min.css" layer(tinymce);
@import "node_modules/tinymce/skins/ui/oxide/skin.shadowdom.min.css"
  layer(tinymce);
