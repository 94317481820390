/// Font of the theme
/// @group general
$fontFamily: "Almarena Neue", "Inter-Regular", "Inter-Semibold";

/// Size of the font
/// @group general
$fontSize: 1rem;

/// Thickness of the texts
/// @group general
$fontWeight: normal;

/// Primary text color
/// @group general
$textColor: var(--navy);

/// Secondary text color
/// @group general
$textSecondaryColor: #6c757d;

/// Background of a highlighted item
/// @group general
$highlightBg: $primaryColor;

/// Text color of a highlighted item
/// @group general
$highlightTextColor: $primaryTextColor;

/// Background of a highlighted item in focus state
/// @group general
$highlightFocusBg: rgba($primaryColor, .24) !default;

/// Radius of the corners
/// @group general
$borderRadius: .5rem;

/// Duration of the property transitions
/// @group general
$transitionDuration: .2s;

/// Properties of a form element transition
/// @group general
$formElementTransition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

/// Properties of a action icon transition
/// @group general
$actionIconTransition: background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration;

/// Properties of a list item transition
/// @group general
$listItemTransition: background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

/// Size of the Icons
/// @group general
$primeIconFontSize: 1rem;

/// Separator border
/// @group general
$divider: 1px solid #dee2e6;

/// Space between two inline items
/// @group general
$inlineSpacing: .5rem;

/// Opacity of the disabled elements
/// @group general
$disabledOpacity: 1;

/// Background of the modal layer
/// @group general
$maskBg: rgb(33 33 33 / 30%);

/// Font size of the loading icons
/// @group general
$loadingIconFontSize: 2rem;

/// Color to use on an invalid element e.g. invalid input
/// @group general
$errorColor: var(--red-07);

/// Outline color of a focused element
/// @group general
$focusOutlineColor: #bfd1f6;

/// Outline of a focused element
/// @group general
$focusOutline: 0 none;

/// Outline offset of a focused element
/// @group general
$focusOutlineOffset: 0;

/// Outline color of a focused input element
/// @group general
$inputFocusOutlineOffset: $focusOutlineOffset;

/// Box shadow of a focused element
/// @group general
$focusShadow: none;

/// Width of an action icon
/// @group general
$actionIconWidth: 2rem;

/// Height of an action icon
/// @group general
$actionIconHeight: 2rem;

/// Background of an action icon
/// @group general
$actionIconBg: transparent;

/// Border of an action icon
/// @group general
$actionIconBorder: 0 none;

/// Color of an action icon
/// @group general
$actionIconColor: $textSecondaryColor;

/// Backgroun of an action icon in hover state
/// @group general
$actionIconHoverBg: #e9ecef;

/// Border of an action icon in hover state
/// @group general
$actionIconHoverBorderColor: transparent;

/// Color of an action icon in hover state
/// @group general
$actionIconHoverColor: $textColor;

/// Border radius of an action icon
/// @group general
$actionIconBorderRadius: 50%;

/// Scale factor of small component size
/// @group general
$scaleSM:0.875;

/// Scale factor of small large size
/// @group general
$scaleLG:1.25;
