@import "@platform-app/styles/mixins/custom-scrollbar.scss";

// Datatable
.p-datatable-thead {
  @apply subtitle-3-semibold;
  text-transform: uppercase;
}

.p-datatable-wrapper {
  background-color: white;

  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
  scrollbar-gutter: auto;

  &::-webkit-scrollbar-track {
    background-color: $tableHeaderCellBg;
  }

  &::-webkit-scrollbar-track-piece {
   margin-top: 3rem;
   margin-bottom: 0.125rem;
  }
}
