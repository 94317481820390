@import "@platform-app/styles/mixins/custom-scrollbar.scss";

// Autocomplete
.p-autocomplete {
  width: 100%;

  &-multiple-container {
    @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
    display: flex;
    overflow-y: auto;
    height: initial !important;
    padding-top: calc(0.5rem - 0.8px);
    padding-bottom: calc(0.5rem - 0.8px);
    max-height: 7.875rem;

    .p-autocomplete-input-token {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      height: 1.5rem;

      input {
        font-size: $autocompleteInputTextFontSize;
      }
    }
  }

  &-token {
    text-wrap: nowrap;
  }

  &-item {
    text-wrap: wrap;
  }

  &-multiple-container li input {
    min-width: 2rem;
    width: 100%;
  }

  &-input {
    height: 1.25rem;
    padding-top: calc(1.25rem - 0.8px);
    padding-bottom: calc(1.25rem - 0.8px);
  }

  &-panel {
    margin-top: 0.25rem;
  }
}

.p-autocomplete-item,
.p-autocomplete-empty-message {
  @apply body-1-regular;
}

// Calendar
.p-datepicker-icon {
  opacity: $disabledCalendarOpacity;
}

.p-datepicker {
  padding: 0;

  .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: $calendarCellDateTodayBgHover;
  }

  .p-yearpicker-year:not(.p-highlight):not(.p-disabled):hover {
    background: $calendarCellDateTodayBgHover;
  }

  .p-disabled {
    opacity: $disabledCalendarOpacity;
  }

  .p-datepicker-title {
    @apply body-1-regular;
  }

  .p-datepicker-next,
  .p-datepicker-prev {
    height: 1.5rem;
    width: 1.5rem;
  }

  .p-datepicker-calendar-container {
    padding: 0.5rem 1rem 0.75rem;
    min-height: 13.75rem;
    display: flex;
    align-items: center;
  }

  table {
    thead {
      margin: 0.25rem 0;
    }

    th {
      @apply body-1-semibold;
      width: 14%;
    }

    td {
      @apply body-1-regular;

      padding: 0.1875rem 0;

      &:first-child {
        padding-left: 0.375rem;
      }

      &:last-child {
        padding-right: 0.375rem;
      }
    }
  }
}

.p-datepicker-monthpicker {
  @apply body-1-regular;

  padding: 0.5rem;

  .p-monthpicker {
    margin: 0.5rem 0;
  }

  .p-monthpicker-month {
    box-sizing: border-box;
    border: 0.125rem solid white;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}

p-calendar.p-calendar-clearable {
  .p-calendar-clear-icon {
    right: nth($inputPadding, 2) + $calendarPadding;
    margin-top: $calendarCellDatePaddingSM !important;
  }

  .p-inputtext {
    cursor: pointer;
  }
}

// Checkbox
.p-checkbox-box {
  border-radius: 0.25rem;

  &.p-disabled {
    background-color: var(--grey-05);
    border-color: var(--grey-05);
  }
}

.p-checkbox-label {
  @apply body-2-regular;
  cursor: pointer;
}

// Dropdown & Cascade select
.p-dropdown-panel,
.p-cascadeselect-panel {
  overflow: hidden;
}

.p-dropdown-panel {
  margin-top: 0.25rem;
  scrollbar-gutter: unset;
}

.p-dropdown-items-wrapper {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
}

.p-dropdown,
.p-cascadeselect {
  @apply h-10;

  width: 100%;
  border-color: var(--grey-02);

  &:not(.p-disabled).p-focus {
    border-color: $primaryColor;
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}

.p-dropdown-item,
.p-cascadeselect-item,
.p-cascadeselect-label,
.p-dropdown-empty-message,
.p-cascadeselect-empty-message {
  @apply body-1-regular;

  &.p-ripple {
    white-space: normal;
  }

  &.p-highlight {
    color: $textColor;
  }
}

.p-dropdown-item,
.p-cascadeselect-item {
  &.p-highlight {
    background: var(--purple-01);
  }

  &:not(.p-highlight):not(.p-disabled):hover {
    background: var(--grey-01);
  }
}

:not(.ng-invalid) {
  .p-dropdown,
  .p-autocomplete,
  .p-multiselect,
  .p-cascadeselect {
    &:hover {
      border-color: $primaryColor;
    }
  }
}

.p-scroller,
.p-autocomplete-panel,
.p-dropdown-items-wrapper,
.p-cascadeselect-sublist ul,
.p-multiselect-items-wrapper {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
}

.p-dropdown-label.p-inputtext {
  height: auto;
}

// File upload
.p-fileupload {
  .p-message {
    display: none;
  }

  .p-button-link {
    color: var(--purple-06);
  }
}

// Input
.p-inputtext {
  @apply body-1-regular;

  height: 2.5rem;
  width: 100%;

  &:enabled:focus {
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  &.readonly {
    background-color: var(--black-01);
  }
}

// Error Styles for all inputs
.p-inputtext.ng-dirty.ng-invalid,
p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
p-password.ng-dirty.ng-untouched.ng-invalid .p-password .p-inputtext,
.p-inputwrapper.ng-invalid.ng-touched .p-inputwrapper-focus .p-inputtext,
.p-inputwrapper.ng-invalid.ng-touched.p-inputwrapper-focus .p-inputwrapper,
.p-inputwrapper.ng-invalid.ng-touched.p-inputwrapper-focus .p-inputtext {
  border-color: $primaryColor;
}

.p-inputtext.ng-invalid.ng-touched:not(:focus),
.p-inputwrapper.ng-invalid.ng-touched .p-inputtext,
.p-inputwrapper.ng-invalid.ng-touched .p-dropdown:not(.p-inputwrapper-focus),
p-inputmask.ng-touched.ng-invalid:not(.p-inputwrapper-focus) > .p-inputtext,
p-password.ng-invalid.ng-touched .p-password .p-inputtext,
.p-inputwrapper.ng-invalid.ng-touched
  .p-cascadeselect:not(.p-inputwrapper-focus),
.p-inputwrapper.ng-invalid.ng-touched .p-multiselect {
  border-color: $errorColor;
}

.p-inputtextarea {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
}

.p-inputtext:disabled,
.p-inputwrapper:disabled .p-autocomplete-input,
.p-inputwrapper .p-dropdown.p-disabled,
.p-inputwrapper .p-dropdown.p-disabled > .p-inputtext,
p-inputmask:disabled > .p-inputtext {
  background-color: var(--black-01);
  color: var(--grey-05);
}

// InputOtp
.p-inputotp {
  --otp-gap: 1rem;
  --otp-input-size: 3.375rem;

  @media (screen(max-md)) {
    --otp-gap: 0.5rem;
    --otp-input-size: 3rem;
  }

  gap: var(--otp-gap);

  .p-inputotp-input {
    width: var(--otp-input-size);
    height: var(--otp-input-size);
    padding: 0.6875rem 1rem;
    text-align: center;
  }

  .p-inputtext {
    @apply subtitle-1;
    color: var(--navy);
  }
}

// Multiselect
.p-multiselect,
.p-multiselect-item,
.p-multiselect-empty-message {
  @apply body-1-regular;
  width: 100%;

  .p-multiselect-header .p-checkbox {
    display: none;
  }
}

.p-multiselect-panel {
  overflow: hidden;

  .p-multiselect-header {
    background: none;
    padding: $multiselectItemPadding;

    .p-checkbox {
      display: none;
    }

    .p-multiselect-filter {
      box-shadow: none;
      height: 2rem;
    }

    .p-multiselect-close {
      display: none !important;
    }
  }

  .p-multiselect-item {
    padding: $multiselectItemPadding;

    &.p-highlight {
      background: none;
    }

    &:not(.p-highlight):not(.p-disabled) {
      &.p-focus {
        background: none;
      }

      &:hover {
        background: var(--grey-01);
      }
    }
  }
}

// Password
.p-password {
  width: 100%;
}

// Radio Button
p-radiobutton.ng-invalid.ng-touched .p-radiobutton-box {
  border: 1px solid $errorColor;
}

.p-radiobutton-label {
  cursor: pointer;
}

// Slider
.p-slider-range {
  border-radius: 0.5rem;
}
