// Message
.p-inline-message-text,
.p-message-detail {
  @apply body-1-regular;
}

.p-message .p-message-icon {
  line-height: 0.75rem;
  margin-right: 0;
}

// Toast
.p-toast {
  max-width: 22.5rem;

  .p-toast-message {
    @apply border-none;
  }

  .p-toast-message-content {
    @apply m-1 p-1.5 rounded-s;
  }

  .p-toast-summary {
    @apply body-1-semibold text-navy;
  }

  .p-toast-detail {
    @apply body-2-regular text-grey-7;
  }

  .p-toast-icon-close {
    @apply text-grey-5 ml-1;
  }

  .p-toast-message-icon {
    @apply self-center flex items-center ml-2.5;
  }

  .p-toast-message-info .p-toast-message-content {
    border-color: $infoMessageIconColor;
  }

  .p-toast-message-success .p-toast-message-content {
    border-color: $successMessageIconColor;
  }

  .p-toast-message-warn .p-toast-message-content {
    border-color: $warningMessageIconColor;
  }

  .p-toast-message-error .p-toast-message-content {
    border-color: $errorMessageIconColor;
  }
}
