// Divider
.p-divider {
  .p-divider-content {
    padding: 0 1rem;
    color: var(--grey-05);

    @apply body-1-regular;
  }

  &.p-divider-dashed.p-divider-horizontal:before {
    border-top-style: dashed;
  }

  &.p-divider-dotted.p-divider-horizontal:before {
    border-top-style: dotted;
  }

  &.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid;
  }

  &.p-divider-dashed.p-divider-vertical:before {
    border-left-style: dashed;
  }

  &.p-divider-dotted.p-divider-vertical:before {
    border-left-style: dotted;
  }

  &.p-divider-solid.p-divider-vertical:before {
    border-left-style: solid;
  }
}

// Tabview
.p-tabview-nav-link {
  @apply body-1-regular;
  color: var(--grey-05);
  border: none;

  div span {
    line-height: $alignTabLineHeight;
  }
}

.p-highlight > .p-tabview-nav-link {
  @apply body-1-semibold;
  color: $primaryColor;
  border-bottom: 0.3125rem solid $primaryColor;
}

.p-tabview-panels {
  background: transparent;
}

// Stepper
.p-stepper .p-stepper-header {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    flex-grow: 1;
  }

  &.p-highlight {
    .p-stepper-number {
      color: $primaryColor;
      border: 1px solid $primaryColor;
    }

    .p-stepper-title,
    .p-stepper-separator {
      color: $primaryColor;
    }
  }

  .p-stepper-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
  }

  .p-stepper-number {
    @apply body-2-semibold;

    background-color: white;
    aspect-ratio: 1 / 1;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey-02);
    color: var(--grey-02);
  }

  .p-stepper-title {
    @apply body-2-regular;

    max-height: 2rem;
    color: var(--grey-05);
  }

  .p-stepper-separator {
    margin-inline-start: 0;
    background-color: var(--grey-03);
    height: 1px;
  }
}

.p-stepper-nav {
  gap: 0.75rem;
}
