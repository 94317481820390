@import "@platform-app/styles/mixins/custom-scrollbar.scss";

$indentation-step: 1rem;

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ql-editor li {
  padding-left: $indentation-step;
}

.ql-editor {
  @for $i from 1 through 9 {
    .ql-indent-#{$i} {
      padding-left: calc($indentation-step + $indentation-step * #{$i});
    }
  }
}

.ql-editor {
  @include scrollbar(6px, 4px, inset 0 0 25px var(--grey-04), 4px);
}

.ql-editor.ql-blank::before {
  color: var(--grey-05);
}
